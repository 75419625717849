import React from 'react';
import Slider from 'react-slick';

const Team = () => {
    const teamMembers = [
        {
            name: "Erinaldo Sanches",
            imgSrc: `${process.env.PUBLIC_URL}/team/Erinaldo.jpg`,
            description: "Mestre em bioinformática. Especialista em banco de dados. Cientista da computação. Responsável pela análise e arquitetura de software.",
            href: "https://www.linkedin.com/in/erinaldo-sanches-nascimento-925a4938/"
        },
        {
            name: "Daniel Fugimoto",
            imgSrc: `${process.env.PUBLIC_URL}/team/Daniel.jpg`,
            description: "Pós graduado em desenvolvimento de sistemas. Responsável pelo design dos produtos e pelo Front-End.",
            href: "https://www.linkedin.com/in/daniel-fugimoto/"
        },
        {
            name: "Gabriel Henrique",
            imgSrc: `${process.env.PUBLIC_URL}/team/Gabriel.jpeg`,
            description: "Sou formado em Engenharia de Software e possuo uma pós-graduação em Ciências de Dados. Atualmente, ministro aulas de desenvolvimento de sistemas e trabalho no desenvolvimento de projetos relacionados a machine learning."
        },
        {
            name: "Eliel Nascimento",
            imgSrc: `${process.env.PUBLIC_URL}/team/Eliel.jpg`,
            description: "Sou formado em Análise de Sistemas, com especialização em Desenvolvimento de Sistemas focado em backend Java. Além de atuar na análise e arquitetura de software, sou professor, sempre buscando transmitir conhecimento e soluções eficientes de forma prática.",
            href: "https://www.linkedin.com/in/dev-eliel/"
        },
    ];

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],

        pauseOnHover: true,
        adaptiveHeight: true,
        cssEase: 'ease-in-out',
    };


    return (
        <section className="team">
            <div className="team-titulo">
                <h2>Conheça nosso time</h2>
                <h3>Na Birth Code, contamos com uma equipe de pessoas talentosas <br />
                    e apaixonadas por criar soluções inovadoras. Conheça os membros<br />
                    da nossa equipe.
                </h3>
            </div>
            <Slider {...settings} className="team-cards">
                {teamMembers.map((member, index) => (
                    <div className="cards" key={index}>
                            <a href={member.href} target="_blank" rel="noopener noreferrer" >
                                <img src={member.imgSrc} alt={member.name} />
                            </a>
                        <h2>{member.name}</h2>
                        <p>{member.description}</p>
                    </div>
                ))}
            </Slider>
        </section>
    );
};

export default Team;
