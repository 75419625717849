import React from 'react';

const Contact = () => {
    return (
        <section className="contato">
            <div className="contato-titulo">
                <h1>Entre em contato</h1>
            </div>
            <div className="contato-card">
                {/* 
                <div className="contato-info">
                    <h3>Telefone</h3>
                    <p>+55 44 99999-9999</p>
                </div>
                */}
                
                <div className="contato-info">
                    <h3>E-mail</h3>
                    <p>contato@birthcode.com.br</p>
                </div>
                
                {/* 
                <div className="contato-info">
                    <h3>Endereço</h3>
                    <p>Rua Tal do tal, 123</p>
                </div>
                */}

                <div className="contato-info">
                    <h3>Instagram</h3>
                    <a href='https://www.instagram.com/birthcodemga?igsh=MjQyOGloNno0MWw4' target="_blank" rel="noopener noreferrer">Birth Code</a>
                </div>

            </div>
            <a href="mailto:contato@birthcode.com.br?subject=Contato&body=Olá, gostaria de mais informações sobre seus serviços." 
            target="_blank" rel="noopener noreferrer">
                <button>Entre em Contato</button>
            </a>
        </section>
    );
};

export default Contact;
