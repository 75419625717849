import React from 'react';
import './App.css';
import Header from './componentes/Header';
import Services from './componentes/Services';
import Team from './componentes/Team';
import Contact from './componentes/Contact';
import Footer from './componentes/Footer';

function App() {

  const scrollToSection = () => {
    document.querySelector('.servicos').scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <div className="App">
      <div className="main">
        <Header scrollToSection={scrollToSection} />
        <Services />
        <hr/>
        <Team />
        <hr/>
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;