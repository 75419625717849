import React, { useEffect, useState, useRef } from 'react';

const Services = () => {
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (cardRef.current) {
                const rect = cardRef.current.getBoundingClientRect();
                if (rect.top <= window.innerHeight * 0.75) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="servicos">
            <h2>Nossos Serviços</h2>
            <h3>Descubra a gama de serviços oferecidos pela nossa software house.</h3>

            <div className="card" ref={cardRef}>
                <div className={`servicos-card ${isVisible ? 'visible' : ''}`}>
                    <h1 className="card-titulo">Desenvolvimento de Software</h1>
                    <p className="card-texto">Transforme suas ideias em soluções de software de ponta.</p>
                </div>
                <div className={`servicos-card ${isVisible ? 'visible' : ''}`}>
                    <h1 className="card-titulo">Web Design</h1>
                    <p className="card-texto">Crie sites visualmente impressionantes que cativam seu público.</p>
                </div>
                <div className={`servicos-card ${isVisible ? 'visible' : ''}`}>
                    <h1 className="card-titulo">Análise de Dados</h1>
                    <p className="card-texto">Obtenha informações valiosas dos seus dados para tomar decisões de negócios informadas.</p>
                </div>
                <div className={`servicos-card ${isVisible ? 'visible' : ''}`}>
                    <h1 className="card-titulo">Consultoria</h1>
                    <p className="card-texto">Obtenha orientação especializada para otimizar suas estratégias de negócios.</p>
                </div>
            </div>
        </section>
    );
};

export default Services;
