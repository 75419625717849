import React from 'react';

const Header = ({ scrollToSection }) => {
    return (
        <section className="header">
            <div className="header-left">
                <h1>Bem-vindo a <span className="custom-font">Birth Code</span></h1>
                <p>Desbloqueie o potencial da sua presença digital com as nossas soluções.</p>
                <button onClick={scrollToSection}>Vamos começar</button>
            </div>
            <div className="header-right">
                <img src={`${process.env.PUBLIC_URL}/logo 02.jpg`} alt="Logo" />
            </div>
        </section>
    );
};

export default Header;
